<template>
  <section class="section" id="contact">
    <div class="width-limiter">
      <h2 class="section-header" data-aos="zoom-in">Kontakt</h2>

      <div class="flex-wrapper">
        <div class="form-wrapper"><contact-form></contact-form></div>
        <div class="icon-wrapper">
          <contact-icon></contact-icon>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ContactForm from "./ContactForm";
import ContactIcon from "./icons/ContactIcon";

export default {
  components: {
    ContactForm,
    ContactIcon,
  },
};
</script>

<style lang="scss" scoped>
.section {
  color: #fff;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
  margin-bottom: 2rem;
  overflow: hidden;
}

.width-limiter {
  max-width: 200rem;
  margin-left: auto;
  margin-right: auto;
}

.form-wrapper {
  width: 80rem;
}

.icon-wrapper {
  width: 30rem;
  padding: 2rem;
  display: none;
  fill: var(--primary-color);
}

.flex-wrapper {
  margin-top: 5rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

@media only screen and (min-width: 1200px) {
  .icon-wrapper {
    display: block;
  }
}

@media only screen and (min-width: 600px) {
  .flex-wrapper {
    margin-top: 8rem;
  }
}
</style>
