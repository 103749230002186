<template>
  <svg
    class="logo"
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 60 60"
    style="enable-background:new 0 0 60 60;"
    xml:space="preserve"
  >
    <g>
      <path
        d="M55.849,47.834c-0.429-4.107-3.852-7.332-7.953-7.332c-2.564,0-4.984,1.286-6.48,3.402
		c-0.291-0.051-0.586-0.076-0.883-0.076c-2.32,0-4.381,1.577-5.066,3.813c-1.907,1.131-3.116,3.241-3.116,5.502
		c0,3.507,2.785,6.359,6.208,6.359h10.774c0.06,0,0.118-0.004,0.171-0.008c0.062,0.004,0.124,0.008,0.188,0.008h4.31
		c3.225,0,5.849-2.687,5.849-5.989C59.85,50.913,58.208,48.64,55.849,47.834z M54.001,57.502l-4.352-0.003l-0.17-0.006
		l-10.922,0.009c-2.32,0-4.208-1.956-4.208-4.359c0-1.665,0.949-3.21,2.417-3.935l0.435-0.214l0.102-0.475
		c0.331-1.56,1.689-2.691,3.229-2.691c0.345,0,0.688,0.057,1.02,0.168l0.777,0.261l0.408-0.711c1.096-1.906,3.024-3.044,5.158-3.044
		c3.265,0,5.955,2.747,5.998,6.123l0.011,0.813l0.799,0.155c1.823,0.354,3.146,2.002,3.146,3.919
		C57.85,55.712,56.123,57.502,54.001,57.502z"
      />
      <path
        d="M10.5,6.498c-2.481,0-4.5,2.019-4.5,4.5s2.019,4.5,4.5,4.5s4.5-2.019,4.5-4.5S12.981,6.498,10.5,6.498z M10.5,13.498
		c-1.379,0-2.5-1.121-2.5-2.5s1.121-2.5,2.5-2.5s2.5,1.121,2.5,2.5S11.879,13.498,10.5,13.498z"
      />
      <circle cx="50" cy="9.498" r="1" />
      <circle cx="46" cy="9.498" r="1" />
      <circle cx="52" cy="12.498" r="1" />
      <circle cx="48" cy="12.498" r="1" />
      <circle cx="42" cy="9.498" r="1" />
      <circle cx="44" cy="12.498" r="1" />
      <circle cx="38" cy="9.498" r="1" />
      <circle cx="40" cy="12.498" r="1" />
      <circle cx="34" cy="9.498" r="1" />
      <circle cx="36" cy="12.498" r="1" />
      <path
        d="M60,16.89V5.106c0-2.541-2.067-4.608-4.608-4.608H4.608C2.067,0.498,0,2.565,0,5.106V16.89
		c0,1.469,0.703,2.764,1.777,3.608C0.703,21.343,0,22.638,0,24.106V35.89c0,1.469,0.703,2.764,1.777,3.608
		C0.703,40.343,0,41.638,0,43.106V54.89c0,2.541,2.067,4.608,4.608,4.608H28v-2H4.608C3.17,57.498,2,56.328,2,54.89V43.106
		c0-1.438,1.17-2.608,2.608-2.608H38v-2H4.608C3.17,38.498,2,37.328,2,35.89V24.106c0-1.438,1.17-2.608,2.608-2.608h50.783
		c1.438,0,2.608,1.17,2.608,2.608v14.392h2V24.106c0-1.469-0.703-2.764-1.777-3.608C59.297,19.653,60,18.358,60,16.89z
		M4.608,19.498C3.17,19.498,2,18.328,2,16.89V5.106c0-1.438,1.17-2.608,2.608-2.608h50.783C56.83,2.498,58,3.668,58,5.106V16.89
		c0,1.438-1.17,2.608-2.608,2.608H4.608z"
      />
      <path
        d="M6,29.998c0,2.481,2.019,4.5,4.5,4.5s4.5-2.019,4.5-4.5s-2.019-4.5-4.5-4.5S6,27.517,6,29.998z M13,29.998
		c0,1.379-1.121,2.5-2.5,2.5S8,31.377,8,29.998s1.121-2.5,2.5-2.5S13,28.619,13,29.998z"
      />
      <circle cx="50" cy="28.498" r="1" />
      <circle cx="46" cy="28.498" r="1" />
      <circle cx="52" cy="31.498" r="1" />
      <circle cx="48" cy="31.498" r="1" />
      <circle cx="42" cy="28.498" r="1" />
      <circle cx="44" cy="31.498" r="1" />
      <circle cx="38" cy="28.498" r="1" />
      <circle cx="40" cy="31.498" r="1" />
      <circle cx="34" cy="28.498" r="1" />
      <circle cx="36" cy="31.498" r="1" />
      <path
        d="M6,48.998c0,2.481,2.019,4.5,4.5,4.5s4.5-2.019,4.5-4.5s-2.019-4.5-4.5-4.5S6,46.517,6,48.998z M13,48.998
		c0,1.379-1.121,2.5-2.5,2.5S8,50.377,8,48.998s1.121-2.5,2.5-2.5S13,47.619,13,48.998z"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>
