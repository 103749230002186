<template>
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 349.255 349.255"
    style="enable-background:new 0 0 349.255 349.255;"
    xml:space="preserve"
  >
    <path
      d="M278.509,349.255c-0.162,0-0.324-0.005-0.487-0.015l-226.03-13.787c-4.41-0.269-7.767-4.062-7.498-8.473
	s4.055-7.754,8.473-7.498l217.543,13.269v-5.487c0-4.418,3.582-8,8-8s8,3.582,8,8v13.99c0,2.206-0.911,4.314-2.518,5.827
	C282.503,348.482,280.54,349.255,278.509,349.255z M296.777,309.159H52.478c-4.418,0-8-3.582-8-8V8c0-4.418,3.582-8,8-8h244.299
	c4.418,0,8,3.582,8,8v293.159C304.777,305.578,301.195,309.159,296.777,309.159z M60.478,293.159h228.299V16H60.478V293.159z
	M186.718,261.63h-24.183c-4.418,0-8-3.582-8-8s3.582-8,8-8h24.183c4.418,0,8,3.582,8,8S191.136,261.63,186.718,261.63z
	M147.375,194.277c-1.497,0-2.987-0.419-4.29-1.247c-2.311-1.468-3.711-4.016-3.711-6.753v-56.114
	c-7.776-8.502-12.526-19.816-12.526-32.219c0-26.346,21.434-47.78,47.779-47.78s47.78,21.434,47.78,47.78
	c0,26.346-21.435,47.78-47.78,47.78c-6.846,0-13.36-1.448-19.253-4.052v32.007l15.021-7.056c2.097-0.984,4.516-1.013,6.636-0.077
	l16.851,7.448v-17.397c0-4.418,3.582-8,8-8s8,3.582,8,8v29.679c0,2.705-1.367,5.227-3.634,6.704
	c-2.267,1.476-5.124,1.707-7.601,0.614l-24.749-10.939l-23.122,10.862C149.694,194.025,148.532,194.277,147.375,194.277z
	M174.627,66.165c-17.523,0-31.779,14.256-31.779,31.78c0,17.524,14.256,31.78,31.779,31.78s31.78-14.256,31.78-31.78
	C206.407,80.421,192.15,66.165,174.627,66.165z"
    />
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>
