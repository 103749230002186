<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 507.998 507.998"
    style="enable-background:new 0 0 507.998 507.998;"
    xml:space="preserve"
  >
    <g>
      <g>
        <path
          d="M491.5,415.788l-82.5-82.4c-10.1-10.1-23.5-15.6-37.8-15.6c-9.4,0-18.5,2.5-26.5,7.1l-26.6-26.6
			c27.1-31.6,43.5-72.6,43.5-117.3c0-99.6-81.1-180.7-180.8-180.7C81.1,0.188,0,81.188,0,180.888c0,99.6,81.1,180.7,180.8,180.7
			c44.8,0,85.8-16.4,117.4-43.4l26.6,26.6c-4.5,8-7,17-7,26.4c0,14.3,5.6,27.7,15.7,37.8l83.6,83.6c24.5,24.5,61.9,16.3,79.1-3.8
			C513.7,467.988,511.4,435.688,491.5,415.788z M180.8,333.388c-37.6,0-72.1-13.7-98.7-36.4c9.2-28.8,30.7-52.4,58.5-64.1l28.4,43.6
			c2.6,4,7.1,6.4,11.8,6.4c4.8,0,9.2-2.4,11.8-6.4l28.4-43.6c27.8,11.7,49.3,35.2,58.5,64.1
			C252.9,319.588,218.4,333.388,180.8,333.388z M219.4,202.288c-6-1.8-12.5,0.5-15.9,5.8l-22.7,34.8l-22.6-34.8
			c-3.4-5.3-9.9-7.6-15.9-5.8c-36.6,11.1-66.3,37.8-81.8,72c-13-16.7-22.7-36.2-27.8-57.4H53c7.8,0,14.1-6.3,14.1-14.1
			c0-7.8-6.3-14.1-14.1-14.1H28.4c-0.1-2.6-0.2-5.2-0.2-7.9c0-6.9,0.5-13.7,1.4-20.3h58.6c7.8,0,14.1-6.3,14.1-14.1
			c0-7.8-6.3-14.1-14.1-14.1h-52c20.3-60.3,77.5-103.9,144.6-103.9c84.1,0,152.6,68.4,152.6,152.5c0,35.2-12.1,67.7-32.2,93.5
			C285.7,240.088,256,213.488,219.4,202.288z M474.4,470.688c-9.7,10.9-27.9,11.2-37.3,1.9l-83.6-83.6c-9.3-9.3-11.7-24,0-35.6
			c12.4-12.4,28.1-7.5,35.6,0l82.5,82.4C481.3,445.488,482.9,461.088,474.4,470.688z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M180.8,53.488c-35.3,0-64,28.7-64,64s28.7,64,64,64c35.3,0,64-28.7,64-64C244.8,82.188,216.1,53.488,180.8,53.488z
			M180.8,153.188c-19.7,0-35.8-16-35.8-35.7c0-19.7,16-35.7,35.8-35.7c19.8,0,35.8,16,35.8,35.7
			C216.6,137.188,200.5,153.188,180.8,153.188z"
        />
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>
