<template>
  <section class="section" id="about">
    <div class="width-limiter">
      <h2 class="section-header" data-aos="zoom-in">O mnie</h2>

      <div class="about-cards">
        <about-card>
          <template #title>
            W skrócie...
          </template>

          <template #icon>
            <person-icon></person-icon>
          </template>

          <template #content>
            Nazywam się Jakub Padło, mam 20 lat i mieszkam w Polsce.
            Programowaniem zacząłem się interesować, gdy miałem około 15 lat.
            Swoją naukę rozpocząłem od C++, był to idealny start, gdyż dzięki
            temu zdobyłem obszerną wiedzę, która sprawiła, że szybko uczę się
            nowych technologii i czuje się w nich pewny. Aktualnie większość
            czasu poświęcam na naukę rozwiązań Backendowych. Interesuje się
            również sieciami neuronowymi oraz elektroniką. Uwielbiam uczyć się
            nowych rzeczy i pogłębiać swoją wiedzę.
          </template>
        </about-card>

        <about-card>
          <template #title>
            Zawód i doświadczenie
          </template>

          <template #icon>
            <experience-icon></experience-icon>
          </template>

          <template #content>
            Przykłady moich projektów można znaleźć na moim profilu w serwisie
            <a
              class="text-link"
              href="https://github.com/JakubesP"
              target="_blank"
              >Github</a
            >. Moja wiedza pochodzi z samodzielnej nauki, posiadam również
            kwalifikacje zawodowe <span class="bold-text">EE.08</span> i wkrótce
            <span class="bold-text">EE.09</span>. Na obecną chwilę nie posiadam
            jeszcze doświadczenia komercyjnego.
          </template>
        </about-card>
      </div>
    </div>
  </section>
</template>

<script>
import AboutCard from "./AboutCard";
import PersonIcon from "./icons/PersonIcon";
import ExperienceIcon from "./icons/ExperienceIcon";

export default {
  components: {
    AboutCard,
    PersonIcon,
    ExperienceIcon,
  },
};
</script>

<style lang="scss" scoped>
.section {
  background: var(--bg-color-2);

  color: #fff;
  padding: 1rem;
  overflow: hidden;
}

.about-cards {
  padding-bottom: 5rem;
  margin-top: 5rem;
}

@media only screen and (min-width: 600px) {
  .about-cards {
    margin-top: 8rem;
  }
}

.text-link {
  &:link,
  &:visited {
    display: inline-block;
    color: var(--primary-color);
    font-weight: bold;
    text-decoration: none;
    transition: all 0.2s;
  }

  &:hover {
    background: var(--primary-color);
    transform: scale(1.2) rotateZ(10deg);
    color: #fff;
  }
}

.bold-text {
  font-weight: bold;
  color: var(--primary-color);
}
</style>
