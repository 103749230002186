<template>
  <section class="card">
    <div class="logo">
      <slot name="icon"></slot>
    </div>
    <div class="content">
      <h2 class="header">
        <slot name="title"></slot>
      </h2>
      <p>
        <slot name="content"></slot>
      </p>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.card {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100rem;

  .logo {
    width: 15rem;
    fill: var(--primary-color);
    padding: 1rem;
    display: none;
  }

  .content {
    color: #cecece;
    padding: 1rem;
    margin: 1rem;
    font-size: 2rem;
    line-height: 120%;
    flex: 1;
  }

  .header {
    font-size: 2.5rem;
    color: var(--primary-color);
    font-weight: normal;
    margin-bottom: 2rem;
  }
}

@media only screen and (min-width: 800px) {
  .card {
    flex-direction: row;

    margin-bottom: 10rem;
    margin-left: auto;
    margin-right: auto;

    .logo {
      display: block;
    }
  }
}

@media only screen and (min-width: 1200px) {
  .card {
    border-right: 5px solid var(--primary-color);
    border-left: 5px solid var(--primary-color);
  }
}
</style>
