import { createApp } from "vue";
import Vue3SmoothScroll from "vue3-smooth-scroll";
import VueFinalModal from 'vue-final-modal';
import 'aos/dist/aos.css'

import App from "./App.vue";

const app = createApp(App);

app.use(Vue3SmoothScroll, { updateHistory: false, duration: 400, });
app.use(VueFinalModal());

app.config.globalProperties.apiPrefix = process.env.VUE_APP_API_PREFIX;

app.mount("#app");
