<template>
  <teleport to="body">
    <vue-final-modal
      v-model="showModal"
      classes="modal-container"
      content-class="modal-content"
    >
      <span class="modal__title">{{ modalMessage }}</span>
      <button
        class="modal__close"
        :class="{
          'modal__close--success': modalStatus === 'success',
          'modal__close--fail': modalStatus === 'fail',
        }"
        @click="closeModal"
      >
        <close-icon></close-icon>
      </button>
    </vue-final-modal>
  </teleport>

  <form name="contact" class="form" @submit.prevent="sendMessage" method="POST" ref="contactForm" data-netlify="true">
    <div class="form-group">
      <label for="" class="label">Email:</label>
      <input
        type="email"
        class="input"
        placeholder="> Wpisz swój email"
        v-model="email"
        name="email"
        autocomplete="off"
        required
      />
    </div>

    <div class="form-group">
      <label for="" class="label">Imię:</label>
      <input
        type="text"
        class="input"
        placeholder="> Wpisz swoje imię"
        v-model="nick"
        name="nick"
        autocomplete="off"
        required
      />
    </div>

    <div class="form-group">
      <label for="" class="label">Wiadomość:</label>
      <textarea
        class="textarea"
        cols="10"
        rows="5"
        placeholder="> Twoja wiadomość"
        v-model="message"
        name="message"
        autocomplete="off"
        required
      ></textarea>
    </div>

    <div class="form-group">
      <div class="submit-wrapper">
        <div v-if="loadingState" class="lds-ripple">
          <div></div>
          <div></div>
        </div>
        <button v-else type="submit" class="submit">Wyślij</button>
      </div>
    </div>
  </form>
</template>

<script>
import axios from "axios";
import CloseIcon from "./icons/CloseIcon";

export default {
  components: {
    CloseIcon,
  },
  data() {
    return {
      email: "",
      nick: "",
      message: "",

      loadingState: false,

      showModal: false,
      modalMessage: null,
      modalStatus: null,
    };
  },
  methods: {
    async sendMessage() {
      this.loadingState = true;

      // const contactForm = this.$refs.contactForm;
      // console.log(contactForm);
      const formData = new FormData();
      formData.append('email', this.email);
      formData.append('nick', this.nick);
      formData.append('message', this.message);



      try {
        await axios.post("/", new URLSearchParams(formData).toString(), {
          headers: {
             "Content-Type": "multipart/form-data",
          },
        });
        // await fetch("/", {
        //   method: "POST",
        //   headers: { "Content-Type": "application/x-www-form-urlencoded" },
        //   body: new URLSearchParams(formData).toString(),
        // });
        this.showSuccessModal();
      } catch (err) {
        console.log(err);
        this.showFailModal();
      } finally {
        this.loadingState = false;
      }
    },
    closeModal() {
      this.showModal = false;
    },
    showSuccessModal() {
      this.modalStatus = "success";
      this.modalMessage = "Wiadomość została pomyślnie wysłana";
      this.showModal = true;
    },
    showFailModal() {
      this.modalStatus = "fail";
      this.modalMessage = "Wystąpił błąd :( Spróbuj później";
      this.showModal = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  max-width: 100rem;
  margin: 0 auto;

  .form-group {
    padding: 1rem;

    .label {
      display: block;
      margin-bottm: 1rem;
      font-size: 2rem;
      color: var(--primary-color);
      font-weight: bold;
    }

    .input {
      display: block;
      width: 100%;
      margin: 1rem 0;
      font-family: inherit;
      font-size: 2rem;
      padding: 1.5rem;
      background: #2b2b2b;
      color: #fff;
      outline: unset;
      border: unset;
      border-left: 5px solid var(--primary-color);
    }

    .textarea {
      display: block;
      width: 100%;
      margin: 1.5rem 0;
      font-family: inherit;
      resize: vertical;
      padding: 1rem;
      font-size: 2rem;
      background: #2b2b2b;
      color: #fff;
      outline: unset;
      border: unset;
      border-left: 5px solid var(--primary-color);
    }

    .submit {
      display: block;
      width: 100%;
      font-size: 1.6rem;
      padding: 1.5rem;
      margin: 1.5rem 0;
      border: 3px solid var(--primary-color);
      max-width: 20rem;
      margin: 0 auto;
      background: unset;
      border-radius: 1rem;
      outline: unset;
      color: var(--primary-color);
      text-transform: uppercase;
      transition: all 0.2s;
      cursor: pointer;

      &:hover {
        background: var(--primary-color);
        color: #000;
      }
    }
  }
}

.submit-wrapper {
  display: grid;
  place-items: center;
  height: 10rem;
}

// -----------------------------------------------------------
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid var(--primary-color);
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
</style>

<style lang="scss" scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

::v-deep .modal-content {
  display: flex;
  align-items: center;
  font-size: 2rem;
  padding: 2rem;
  margin: 1rem;
  background: #1f1f1f;
  border-radius: 1rem;
  color: #fff;
}

.modal__title {
  display: block;
  padding: 1rem;
}

.modal__close {
  display: block;
  border: unset;
  background: unset;
  outline: unset;
  padding: 1rem;
  width: 4rem;
  transition: all 0.2s;
  fill: #fff;

  &--success {
    fill: var(--primary-color);
  }

  &--fail {
    fill: #c70000;
  }

  &:hover {
    transform: scale(1.5);
  }
}
</style>
