<template>
  <section class="section" id="skills">
    <div class="width-limiter">
      <h2 class="section-header" data-aos="zoom-in">Umiejętności</h2>

      <div class="skills-flex">
        <skills-card
          title="Backend"
          :skills="[
            'MongoDB',
            'MySQL',
            'Redis',
            'Node.js',
            'Express.js',
            'Nest.js',
          ]"
        >
          <template #icon>
            <backend-icon></backend-icon>
          </template>
        </skills-card>

        <!------------------------------------------------------------------------------>

        <skills-card
          title="Frontend"
          :skills="['JS', 'Vue.js', 'HTML5', 'CSS3', 'SCSS']"
        >
          <template #icon>
            <frontend-icon></frontend-icon>
          </template>
        </skills-card>

        <!------------------------------------------------------------------------------>

        <skills-card
          title="Inne technologie"
          :skills="['C++', 'Rust', 'Python', 'WASM', 'TS']"
        >
          <template #icon>
            <technologies-icon></technologies-icon>
          </template>
        </skills-card>

        <!------------------------------------------------------------------------------>

        <skills-card title="Narzędzia" :skills="['GIT', 'Docker', 'Ubuntu', 'AWS']">
          <template #icon>
            <tools-icon></tools-icon>
          </template>
        </skills-card>
      </div>
    </div>
  </section>
</template>

<script>
import SkillsCard from "./SkillsCard";

import BackendIcon from "./icons/BackendIcon";
import FrontendIcon from "./icons/FrontendIcon";
import TechnologiesIcon from "./icons/AdditionalSkillsIcon";
import ToolsIcon from "./icons/ToolsIcon";

export default {
  components: {
    SkillsCard,
    BackendIcon,
    FrontendIcon,
    TechnologiesIcon,
    ToolsIcon,
  },
};
</script>

<style lang="scss" scoped>
.section {
  color: #fff;
  padding: 1rem;
  overflow: hidden;
}

.skills-flex {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding-bottom: 5rem;
  margin-top: 5rem;
}

@media only screen and (min-width: 600px) {
  .skills-flex {
    margin-top: 8rem;
  }
}
</style>
