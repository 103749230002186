<template>
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 443.353 443.353"
    style="enable-background:new 0 0 443.353 443.353;"
    xml:space="preserve"
  >
    <g>
      <path
        d="M420.458,66.449H22.895C10.271,66.449,0,76.72,0,89.344v225.307c0,12.624,10.271,22.895,22.895,22.895h120.96v27.358h-23
		c-3.313,0-6,2.687-6,6s2.687,6,6,6h201c3.313,0,6-2.687,6-6s-2.687-6-6-6h-23v-27.358h121.604
		c12.624,0,22.895-10.271,22.895-22.895V89.344C443.353,76.72,433.082,66.449,420.458,66.449z M22.895,78.449h397.563
		c6.007,0,10.895,4.887,10.895,10.895v206.56H12V89.344C12,83.336,16.887,78.449,22.895,78.449z M286.854,364.903h-131v-27.358h131
		V364.903z M420.458,325.545H22.895c-6.007,0-10.895-4.887-10.895-10.895v-6.748h419.353v6.748
		C431.353,320.658,426.465,325.545,420.458,325.545z"
      />
      <rect x="35.854" y="105.903" width="74" height="12" />
      <rect x="35.854" y="126.903" width="203" height="12" />
      <rect x="35.854" y="148.903" width="243" height="12" />
      <rect x="35.854" y="168.903" width="28" height="12" />
      <rect x="35.854" y="190.903" width="172" height="12" />
      <rect x="35.854" y="211.903" width="72" height="12" />
      <rect x="35.854" y="233.903" width="18" height="12" />
      <rect x="35.854" y="254.903" width="251" height="12" />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>
