<template>
  <span>
    {{ text
    }}<span v-if="started && !(done && hideCursor)" class="cursor"></span>
    <span v-if="!started">&nbsp;</span>
  </span>
</template>

<script>
export default {
  props: {
    desired: {
      type: String,
      required: true,
    },
    animate: {
      type: Boolean,
      default: true,
    },
    hideCursor: {
      type: Boolean,
      default: false,
    },
    timing: {
      type: Number,
      default: 90,
    },
    delay: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      text: "",
      started: false,
      done: false,
    };
  },
  mounted() {
    if (this.animate) {
      const type = () => {
        if (!this.started) this.started = true;
        if (this.text !== this.desired) {
          this.text += this.desired[this.text.length];
          setTimeout(type, this.timing);
        } else {
          this.done = true;
        }
      };
      setTimeout(type, this.delay);
    } else {
      this.started = true;
      this.done = true;
      this.text = this.desired;
    }
  },
};
</script>

<style scoped>
.cursor {
  animation-name: cur;
  animation-duration: 0.7s;
  animation-iteration-count: infinite;
  animation-timing-function: step-end;
}

.cursor::after {
  content: "_";
  display: inline-block;
  overflow: visible;
  width: 1px;
}

@keyframes cur {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
