<template>
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 490 490"
    style="enable-background:new 0 0 490 490;"
    xml:space="preserve"
  >
    <path
      d="M486.945,142.045L347.906,2.934c-3.911-3.912-10.836-3.912-14.746,0L72.455,263.764c-1.209,1.21-2.051,2.645-2.542,4.168
	c-0.001-0.001-0.002-0.002-0.004-0.003L0.415,476.504c-1.75,6.382,1.926,15.232,13.189,13.2l208.473-69.523
	c-0.002-0.003-0.003-0.005-0.005-0.008c1.557-0.503,2.99-1.365,4.169-2.544l260.705-260.83
	C491.018,152.723,491.018,146.121,486.945,142.045z M421.372,105.952L175.417,352.026l-37.394-37.414L383.977,68.539
	L421.372,105.952z M340.533,25.064l28.703,28.717L123.277,299.859l-28.703-28.718L340.533,25.064z M26.791,463.31l57.59-172.851
	l115.178,115.234L26.791,463.31z M218.868,395.499l-28.706-28.72l245.958-246.077l28.705,28.72L218.868,395.499z"
    />
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>
