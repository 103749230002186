<template>
  <section class="card">
    <div class="logo">
      <slot name="icon"></slot>
    </div>

    <h3 class="header">{{ title }}</h3>
    <ul class="skills">
      <li class="skills__item" v-for="skill in skills" :key="skill">
        {{ skill }}
      </li>
    </ul>
  </section>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    skills: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  margin: 2rem;
  padding: 3rem;
  background-color: rgb(19, 19, 19);
  color: #fff;
  width: 30rem;
  border-radius: 1rem;
  min-height: 40rem;
  outline: 2px solid transparent;

  .logo {
    width: 10rem;
    display: block;
    margin-left: auto;
    margin-right: auto;
    fill: var(--primary-color);
  }

  .header {
    font-size: 2.5rem;
    font-weight: normal;
    text-align: center;
    margin: 2rem;
    color: var(--primary-color);
  }

  .skills {
    list-style-type: none;
    display: flex;
    margin: 1rem;
    flex-wrap: wrap;

    &__item {
      border-radius: 5px;
      display: inline-block;
      padding: 0.7rem;
      margin: 1rem;
      font-size: 1.6rem;
      border: 2px solid #fff;
    }
  }
}
</style>
