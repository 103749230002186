<template>
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 442 442"
    style="enable-background:new 0 0 442 442;"
    xml:space="preserve"
  >
    <g>
      <path
        d="M232.307,138.009c-3.904,3.906-3.904,10.237,0.002,14.143s10.238,3.905,14.142-0.002l8.257-8.26
		c2.97-2.971,3.767-7.481,1.994-11.29c-5.51-11.84-8.304-24.089-8.304-36.408c0-25.079,10.917-48.7,29.331-65.02l0.032,65.461
		c0.001,3.279,1.611,6.35,4.307,8.217l47.446,32.854c3.423,2.37,7.958,2.372,11.382,0.002l47.463-32.837
		c2.7-1.868,4.311-4.941,4.311-8.225l-0.007-65.457c18.412,16.305,29.33,39.907,29.336,64.965c0,23.19-9.029,44.993-25.425,61.392
		c-16.383,16.387-38.159,25.408-61.325,25.408c-0.022,0-0.045,0-0.068,0c-7.971-0.006-23.618-3.865-28.975-5.352
		c-3.473-0.966-7.199,0.015-9.748,2.563l-30.402,30.403c-3.905,3.905-3.905,10.237,0,14.143c1.953,1.952,4.512,2.929,7.071,2.929
		s5.119-0.977,7.071-2.929l26.353-26.354c7.347,1.817,19.976,4.591,28.614,4.597c0.03,0,0.055,0,0.084,0
		c28.505-0.001,55.307-11.104,75.467-31.268c20.172-20.176,31.281-47,31.281-75.534c-0.009-38.434-20.863-74.074-54.423-93.013
		c-3.096-1.747-6.887-1.72-9.958,0.073c-3.07,1.793-4.958,5.081-4.957,8.637l0.008,79.557l-37.46,25.916L297.76,91.387
		l-0.039-79.563c-0.002-3.555-1.891-6.842-4.961-8.633c-3.07-1.792-6.861-1.817-9.957-0.069
		c-33.557,18.956-54.403,54.618-54.403,93.069c0,12.988,2.48,25.854,7.383,38.343L232.307,138.009z"
      />
      <path
        d="M205.828,270.797L67.106,409.524c-5.213,5.216-12.143,8.09-19.513,8.095c-0.005,0-0.009,0-0.015,0
		c-7.368-0.001-14.299-2.873-19.511-8.085c-5.211-5.217-8.077-12.156-8.068-19.541c0.008-7.363,2.879-14.275,8.097-19.476
		l119.319-119.33c3.905-3.905,3.905-10.236,0-14.142c-3.905-3.904-10.237-3.905-14.142,0.001L13.967,356.364
		C4.975,365.325,0.015,377.26,0,389.97c-0.015,12.731,4.928,24.699,13.922,33.703c8.994,8.994,20.946,13.946,33.656,13.946
		c0.009,0,0.018,0,0.026,0c12.711-0.007,24.661-4.964,33.647-13.955L219.97,284.94c3.905-3.905,3.905-10.237,0-14.143
		C216.064,266.893,209.733,266.893,205.828,270.797z"
      />
      <path
        d="M47.436,377.851c-3.294,0.002-6.388,1.289-8.672,3.585c-4.807,4.777-4.831,12.583-0.054,17.398
		c0.012,0.013,0.024,0.024,0.036,0.037c2.395,2.389,5.539,3.585,8.688,3.585c3.156,0,6.317-1.202,8.73-3.61
		c4.786-4.809,4.772-12.615-0.011-17.383c-2.325-2.33-5.417-3.612-8.709-3.612C47.442,377.851,47.439,377.851,47.436,377.851z"
      />
      <path
        d="M374.381,327.157c-1.036-1.035-2.286-1.83-3.662-2.33l-28.677-10.398l-103.588-103.59l16.743-16.742
		c3.905-3.905,3.906-10.237,0-14.143c-2.964-2.962-7.325-3.678-10.963-2.144L87.552,21.146C78.076,11.639,65.456,6.395,52.017,6.379
		c-0.02,0-0.038,0-0.058,0c-13.439,0-26.071,5.226-35.576,14.72C6.866,30.616,1.629,43.264,1.637,56.714
		c0.007,13.439,5.248,26.075,14.755,35.577l156.684,156.677c-1.534,3.637-0.82,7.998,2.144,10.962
		c3.906,3.904,10.238,3.906,14.142,0l16.739-16.739L309.69,346.779l10.399,28.678c0.499,1.376,1.294,2.627,2.33,3.662l58.124,58.123
		c1.953,1.952,4.512,2.929,7.071,2.929s5.119-0.977,7.071-2.929l37.819-37.819c1.875-1.876,2.929-4.419,2.929-7.071
		s-1.054-5.195-2.929-7.071L374.381,327.157z M217.239,203.768c-0.021,0.021-0.043,0.044-0.064,0.065L186.7,234.308L30.532,78.147
		c-5.732-5.729-8.891-13.344-8.895-21.443c-0.004-8.104,3.152-15.726,8.884-21.458c5.725-5.719,13.337-8.866,21.438-8.866
		c0.012,0,0.024,0,0.035,0c8.093,0.009,15.689,3.165,21.403,8.897l156.175,156.159l-12.268,12.268
		C217.283,203.725,217.261,203.746,217.239,203.768z M387.614,416.029l-49.48-49.48l-10.399-28.678
		c-0.499-1.376-1.294-2.627-2.33-3.662l-105.161-105.16l4.068-4.067l105.16,105.161c1.036,1.035,2.286,1.83,3.662,2.33
		l28.677,10.398l49.48,49.48L387.614,416.029z"
      />
      <path
        d="M58.981,49.573c-3.905-3.904-10.237-3.904-14.142,0c-3.905,3.905-3.905,10.237,0,14.143l137.951,137.95
		c1.952,1.952,4.512,2.929,7.071,2.929s5.119-0.977,7.071-2.929c3.905-3.905,3.905-10.237,0-14.143L58.981,49.573z"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>
