<template>
  <section class="section" id="landing">
    <section>
      <header class="header">
        <h1 class="header__big">
          <text-typing
            desired="&#x3C;&nbsp;Software&nbsp;Developer&nbsp;&#x3E;"
            :timing="40"
            :hideCursor="true"
          ></text-typing>
        </h1>
        <h2 class="header__small">
          <text-typing
            desired="Jakub&nbsp;Padło"
            :timing="60"
            :delay="1200"
          ></text-typing>
        </h2>
      </header>

      <nav class="menu">
        <ul class="menu__items">
          <li class="menu__item menu__item--normal">
            <a class="menu__link" href="#skills" v-smooth-scroll
              >Umiejętności</a
            >
          </li>
          <li class="menu__item menu__item--normal">
            <a class="menu__link" href="#about" v-smooth-scroll data-aos-delay="3000">O mnie</a>
          </li>
          <li class="menu__item menu__item--marked">
            <a class="menu__link" href="#contact" v-smooth-scroll data-aos-delay="3000">Kontakt</a>
          </li>
        </ul>
      </nav>
    </section>

    <div class="social">
      <ul class="social__items">
        <li class="social__item">
          <a
            class="social__link"
            href="https://github.com/JakubesP"
            target="_blank"
          >
            <github-icon></github-icon>
          </a>
        </li>
        <li class="social__item">
          <a
            class="social__link"
            href="https://www.linkedin.com/in/jakub-pad%C5%82o-1845831b3/"
            target="_blank"
          >
            <linkedin-icon></linkedin-icon>
          </a>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import LinkedinIcon from "./icons/social/LinkedinIcon";
import GithubIcon from "./icons/social/GithubIcon";
import TextTyping from "./TextTyping";

export default {
  components: {
    LinkedinIcon,
    GithubIcon,
    TextTyping,
  },
};
</script>
TextTyping

<style lang="scss" scoped>
.section {
  min-height: 100vh;
  display: grid;
  place-items: center;
  color: #fff;

  background: url("/background.svg");
  background-position-y: bottom;
  background-repeat: no-repeat;
  background-size: cover;
}

.header {
  margin-bottom: 1em;

  &__big {
    text-align: center;

    font-size: 3rem;
    padding: 0.4em 0;
    color: var(--primary-color);
    font-weight: normal;
  }

  &__small {
    text-align: center;
    font-size: 2rem;

    font-weight: normal;
  }
}

/* menu */

.menu {
  margin: 3rem 0;

  &__items {
    display: flex;
    list-style-type: none;

    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__item {
    display: block;
    font-size: 1.5rem;
    margin: 1rem;
    border-radius: 1rem;
    border: 2px solid #fff;
    text-transform: uppercase;
    transition: all 0.3s;
    width: 15rem;
    text-align: center;

    &--normal {
      &:hover {
        border-color: var(--primary-color);
        color: var(--primary-color);
      }
    }

    &--marked {
      font-weight: bold;
      background-color: var(--primary-color);
      color: #000;
      border: none;
    }
  }

  &__link:link,
  &__link:visited {
    display: inline-block;
    text-decoration: none;
    color: inherit;
    outline: none;
    padding: 1.5rem;
  }
}

/* social  */

.social {
  margin: 1rem 0;

  &__items {
    display: flex;
    list-style-type: none;
    justify-content: center;
  }

  &__item {
    margin: 0 1.3rem;

    display: block;
    width: 6rem;
    fill: #fff;
    transition: all 0.2s;

    svg {
      fill: inherit;
    }


    &:hover {
      fill: var(--primary-color);
      transform: scale(1.5);
    }
  }

  &__link:link,
  &__link:visited {
    outline: none;
  }
}

/* ---------------------------------------------- */

@media only screen and (min-width: 600px) {
  .header {
    margin-bottom: 5rem;
    &__big {
      font-size: 4.5rem;
    }

    &__small {
      font-size: 3rem;
    }
  }

  .menu {
    &__items {
      flex-direction: row;
    }

    &__item {
      width: unset;
      font-size: 2rem;
    }
  }
}

// -----------------------------------------------------------------------------


</style>
