<template>
  <the-landing-section></the-landing-section>
  <the-skills-section></the-skills-section>
  <the-about-section></the-about-section>
  <the-contact-section></the-contact-section>
</template>

<script>
import TheLandingSection from "./components/TheLandingSection";
import TheSkillsSection from "./components/TheSkillsSection";
import TheAboutSection from "./components/TheAboutSection";
import TheContactSection from "./components/TheContactSection";
import AOS from "aos";

export default {
  name: "App",
  components: {
    TheLandingSection,
    TheSkillsSection,
    TheAboutSection,
    TheContactSection,
  },
  created() {
    AOS.init();
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Inconsolata:wght@200;300;400;600&display=swap");

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

:root {
  font-size: 62.5%;
}

body {
  --primary-color: rgb(83, 211, 179);
  // --primary-color: rgb(168, 112, 241);
  //  --primary-color: rgb(112, 189, 241);
  // --primary-color: rgb(253, 152, 105);

  --bg-color-1: #1f1f1f;
  --bg-color-2: #181818;

  font-family: "Inconsolata", monospace;
  box-sizing: border-box;
  background: var(--bg-color-1);
}

::-moz-selection {
  color: #fff;
  background: var(--primary-color);
}

::selection {
  color: #fff;
  background: var(--primary-color);
}

/* global classes */

.width-limiter {
  max-width: 200rem;
  margin-left: auto;
  margin-right: auto;
}

.section-header {
  font-weight: normal;
  text-align: center;
  font-weight: bold;

  margin-top: 2rem;
  font-size: 2.8rem;
}

@media only screen and (min-width: 600px) {
  .section-header {
    margin-top: 5rem;
    font-size: 3.5rem;
  }
}

// ------------------------------------------------------------------------------

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgb(19, 19, 19);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--primary-color);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(66, 66, 66);
}
</style>
